<template>
    <div class="category-edit">
        <edit api='categories' :id="id" title="Category" :fs="categoryFields" @saved="saved"></edit>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { categoryFields } from "@/config";
import Edit from "@/components/Edit";
export default {
    props: ["id"],
    components: {
        Edit
    },
    computed: {
        ...mapGetters({
            categories: "category/categories",
            getChildrenById: "category/getChildrenById"
        }),
        categoryFields() {
            let parentField = categoryFields.find(item => item.data == "parent");
            parentField.options = this.parentCategoriesOptions;
            return categoryFields;
        },
        parentCategoriesOptions() {
            let children = this.getChildrenById(this.id);
            let list = this.categories.filter(item => item._id != this.id && !children.find(child => child._id == item._id)).filter(item => item.level == 1 || item.level == 2);
            return [{ value: null, text: "Root" }].concat(list.map(item => ({ value: item._id, text: `${item.level == 2 ? "    " : " "}${item.name}` })));
        }
    },
    methods: {
        saved() {
            this.$store.dispatch("category/reload");
            this.$router.push("/admin/categories");
        }
    },
    async created() {
        await this.$store.dispatch("category/loadCategories");
    }
};
</script>


