<template>
    <div class="category-list">
        <list :fs="categoryFields" :classKey="'level'" api="categories" editLink='/admin/categories' title='Categories' @deleted="reload" @updated="reload"></list>
        <div style="padding-left:10px;font-size:12px;">all children categories will be remove if parent is removed</div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { categoryFields } from "@/config";
import List from "@/components/List";

export default {
    data() {
        return {};
    },
    components: {
        List
    },
    computed: {
        categoryFields() {
            return categoryFields;
        }
    },
    methods: {
        async reload() {
            await this.$store.dispatch("category/reload");
        }
    }
};
</script>

<style lang="less">
.category-list {
    .md-table-row {
        &.c-1 {
            background: #f1f1f1;
            .md-table-cell:nth-child(1) {
                .md-table-cell-container {
                    font-weight: 800;
                    font-size: 18px;
                }
            }
        }
        &.c-2 {
            background: #fafafa;
            .md-table-cell:nth-child(1) {
                .md-table-cell-container {
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }
        &.c-3 {
            background: white;
            .md-table-cell:nth-child(1) {
                .md-table-cell-container {
                    font-size: 13px;
                    font-weight: 300;
                }
            }
        }
    }
}
</style>