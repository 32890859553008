<template>
    <div class="category-index">
         <md-tabs @change="tabChange">
            <md-tab md-label="Categories" :md-active="tabIndex==0">
                <category-list v-if="tabIndex==0"></category-list>
            </md-tab>
            <md-tab md-label="Add Category" :md-active="tabIndex==1">
                <category-edit v-if="tabIndex==1"></category-edit>
            </md-tab>
            <md-tab md-label="Edit Category" v-if="tabIndex==2" :md-active="tabIndex==2">
                <category-edit :id="id" v-if="tabIndex==2"></category-edit>
            </md-tab>
        </md-tabs>
    </div>
</template>
<script>
import CategoryList from "@/components/Category/CategoryList";
import CategoryEdit from "@/components/Category/CategoryEdit";
export default {
    props: ["id"],
    components: {
        CategoryList,
        CategoryEdit
    },
    data() {
        return {
            tabIndex: 1
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (!this.id) {
                    this.tabIndex = 0;
                } else if (this.id == "new") {
                    this.tabIndex = 1;
                } else {
                    this.tabIndex = 2;
                }
            }
        }
    },
    methods: {
        tabChange(index) {
            if (index == 0) {
                this.$router.push("/admin/categories");
            }
            if (index == 1) {
                this.$router.push(`/admin/categories/new`);
            }
            if (index == 2) {
                this.$router.push(`/admin/categories/${this.id}`);
            }
        }
    }
};
</script>

